import React, { useEffect } from 'react';
import './Contact.scss';
import { FaInstagram, FaYoutube, FaSpotify, FaApple, FaFacebook, FaTwitter, FaEnvelope, FaUserAlt } from 'react-icons/fa';
import { Link as Routing, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
    useEffect(() => {
        AOS.init()
    });

    return (
        <div className='contact_container' name='contact'>
            <h1>Also Follow Me At</h1>
            <BrowserRouter>
                <div className="social-icons">
                    <Routing to="https://www.instagram.com/washington_toe/" target="_blank"><FaInstagram className='icon' /></Routing>
                    <Routing to="https://www.youtube.com/@washingtontoe6261" target="_blank"><FaYoutube className='icon' /></Routing>
                    <Routing to="https://open.spotify.com/artist/3d9YvNhpJ3LKkyQOwNGec3" target="_blank"><FaSpotify className='icon' /></Routing>
                    <Routing to="https://music.apple.com/us/artist/washington-toe/1671595139" target="_blank"><FaApple className='icon' /></Routing>
                    <Routing to="https://www.facebook.com/weston.recs/" target="_blank"><FaFacebook className='icon' /></Routing>
                    <Routing to="https://twitter.com/WashingtonToe3" target="_blank"><FaTwitter className='icon' /></Routing>
                </div>
            </BrowserRouter>

            <div className="email">
                <FaEnvelope className='icon' />
                <p>washington_toe@yahoo.com</p>
            </div>


            <form className='form' data-aos="fade-up">
                <div className="input_field">
                    <input type="text" name='name' id='name' placeholder='Name' required autoSave='false' /><FaUserAlt className='icon1' />
                    <input type="email" name='email' id='email' placeholder='E-mail' required autoSave='false' /><FaEnvelope className='icon2' />
                </div>
                <div className="text">
                    <textarea name="message" id="message" rows="3" required autoSave='false'></textarea>
                </div>

                <input type="submit" id='submit' name='submit' value="SUBMIT" />
            </form>
        </div>
    )
}

export default Contact;