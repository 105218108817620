import React from 'react';
import './Footer.scss';
import { Link } from 'react-scroll';
import {HiArrowNarrowRight} from 'react-icons/hi';

const Footer = () => {
  return (
    <div className='footer_container' name='footer'>
        <div className="heading">
            <h1>Connect With Me</h1>
            <Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="link"><HiArrowNarrowRight className='arrow' /></Link>
        </div>

        <p>Washington S.Toe has achieved success in multiple fields like banking, writing, songwriting, entrepreneurship, and philanthropy. Despite experiencing political and civil unrest in Liberia and Sierra Leone, he has persevered and emerged as a successful individual.
        Washington's experiences growing up in Liberia have influenced his life and shaped his perspective on the world.
        </p>

        <div className="footer_nav">
            <ul className='favNav_menu'>
                <li><a href="/"><Link activeClass="active" to="about" spy={true} smooth={true} duration={700} className="link">Bio</Link></a></li>
                <li><a href="/"><Link activeClass="active" to="gallery" spy={true} smooth={true} duration={700} className="link">Gallery</Link></a></li>
                <li><a href="/"><Link activeClass="active" to="music" spy={true} smooth={true} duration={700} className="link">Music</Link></a></li>
            </ul>

            {/* <a href="/" className='btn' data-aos="fade-left"><HiHeart /> Make a Donation</a> */}
        </div>

        <div className="policies">
            <div className="washington" >
                <p>©washingtontoe</p>
            </div>

            <div className="privacy">
                <li><a href="/">privacy policy</a></li>
                <li><a href="/">terms</a></li>
                <li><a href="/">site maps</a></li>
            </div>
        </div>
    </div>
  )
}

export default Footer;