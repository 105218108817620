import React from 'react';
import './Carousal.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Carousal = () => {
  return (
    <div className='' id='books' name='books'>
      <div className="mobDesign block lg:hidden" id='books' name='books'><MobileDesign /></div>
      <div className="lapDesign hidden lg:block" id='books' name='books'> <LaptopDesign /></div>
    </div>
  )
}
export default Carousal


export const LaptopDesign = () => {
  return (
    <Carousel showStatus={false} id='books' name='books'>
      <div className='secFour_Container' id='books' name='books'>
        <div className="left_img"></div>
        <div className="right_img -translate-x-8"></div>
      </div>

      <div className='secFour_Container' id='books' name='books'>
        <div className="left_img1"></div>
        <div className="right_img1"></div>
      </div>
    </Carousel>
  )
}


export const MobileDesign = () => {
  return (
    <Carousel showStatus={false} id='books' name='books'>
      <div className='secFour_Container ' id='books' name='books'>
        <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
          <img src='/carousalImages/img1.jpeg' alt='' />
        </div>
        <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
          <img src='/carousalImages/img2.jpeg' alt='' />
        </div>
      </div>

      <div className='secFour_Container' id='books' name='books'>
        <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
          <img src='/carousalImages/img3.png' alt='' />
        </div>
        <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
          <img src='/carousalImages/img4.png' alt='' />
        </div>
      </div>
    </Carousel>
  )
}
