import React from 'react';
import './Sec3.scss';
import '../carousal/Carousal.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


const GalleryAndSlider = () => {
  return (
    <div className=' ' >
      <div className="mobDesign block lg:hidden"><MobileDesign /></div>
      <div className="lapDesign hidden lg:block"> <LaptopDesign /></div>
    </div>
  )
}

export default GalleryAndSlider


export const MobileDesign = () => {
  return (
    <div>
      <div className=''><h1 className=' justify-start flex font-normal text-3xl py-5 pl-4 '>Gallery</h1></div>
      <Carousel showStatus={false} autoPlay={true} interval={3000} infiniteLoop={true}>
        <div className='secFour_Container ' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/1.jpg' alt='' />
          </div>
        </div>

        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/2.jpg' alt='' />
          </div>
        </div>
        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/3.jpg' alt='' />
          </div>
        </div>
        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/4.jpg' alt='' />
          </div>
        </div>
        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/5.jpg' alt='' />
          </div>
        </div>
        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/6.jpg' alt='' />
          </div>
        </div>
        <div className='secFour_Container' name='books'>
          <div className=" bg-cover w-[100%] max-w-[400px] h-auto bg-no-repeat">
            <img src='/gallery/7.jpg' alt='' />
          </div>
        </div>
      </Carousel>
    </div>
  )
}








export const LaptopDesign = () => {
  return (
    <>
      <div className='sectionThree_container' name='gallery'>
        <h1>Gallery</h1>

        <div className="image_gallery">
          <div className="top_img">
            <div className="img1 img" ></div>
            <div className="img2 img" ></div>
            <div className="img3 img"></div>
          </div>

          <div className="bottom_img">
            <div className="img4 img"></div>
            <div className="img5 img"></div>
            <div className="img6 img"></div>
            <div className="img7 img"></div>
          </div>
        </div>
      </div>
    </>
  )
}
