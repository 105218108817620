import React, { useState } from 'react'
import { Link } from 'react-scroll';
import { Link as Routing, BrowserRouter } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaAmazon, FaApple, FaSpotify, FaTwitter } from 'react-icons/fa'
import { BsBoxArrowDown } from 'react-icons/bs';


const NavbarFull = () => {
  return (
    <div className=''>
      <div className="mobDesign block lg:hidden"><MobileDesign /></div>
      <div className="lapDesign hidden lg:block"> <LaptopDesign /></div>
    </div>
  )
}

export default NavbarFull



export const MobileDesign = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <div className='' name='home'>
      <div className=' relative' name='home'>
        <img src='/banner/banner.png' alt='loogo' />
        <div className='absolute top-0 left-0 w-screen'>
          <header>
            <div className="px-4 py-2 w-full flex justify-between text-white bg-transparent">
              <div className="logo">
                <img src="/logo.png" alt="logo" />
              </div>
              <div className={toggleMenu ? "absolute top-0 left-0 md:flex w-full md:w-auto bg-black z-10" : "hidden md:flex"} id="menu">
                <ul className='w-full h-full flex flex-col gap-3 pl-3 py-7'>

                  <li><a href="/"><Link activeClass="active" to="home" spy={true} smooth={true} duration={700} className="hover:text-red-500">HOME</Link></a></li>
                  <li><a href="/"><Link activeClass="active" to="about" spy={true} smooth={true} duration={700} className="hover:text-red-500">ABOUT</Link></a></li>
                  <li><a href="/"><Link activeClass="active" to="slide" spy={true} smooth={true} duration={700} className="hover:text-red-500">BOOKS</Link></a></li>
                  <li><a href="/"><Link activeClass="active" to="music" spy={true} smooth={true} duration={700} className="hover:text-red-500">MUSIC</Link></a></li>
                  <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="hover:text-red-500">CONTACT</Link></a></li>
                </ul>
              </div>
              <div className="cursor-pointer md:hidden z-20">
                <input class="menu-btn hidden" type="checkbox" id="menu-btn" />
                <label class="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none" for="menu-btn">
                  <span onClick={handleToggle} class="navicon bg-white-darkest flex items-center relative"></span>
                </label>
              </div>
            </div>
          </header>
        </div>
        <BrowserRouter>
          <div className="icons flex absolute bottom-3 left-16 mt-5">
            <Routing to='https://www.facebook.com/weston.recs/' target="_blank"><FaFacebook className='icon mx-3 text-lg hover:text-red-500' /></Routing>
            <Routing to='https://www.instagram.com/washington_toe/' target="_blank"><FaInstagram className='icon mx-3 text-lg hover:text-red-500' /></Routing>
            <Routing to='https://www.youtube.com/@washingtontoe6261' target="_blank"><FaYoutube className='icon mx-3 text-lg hover:text-red-500' /></Routing>
            <Routing to='#' target="_blank"><FaAmazon className='icon hover:text-red-500' /></Routing>
            <Routing to='https://music.apple.com/us/artist/washington-toe/1671595139' target="_blank"><FaApple className='icon mx-3 text-lg hover:text-red-500' /></Routing>
            <Routing to='https://open.spotify.com/artist/3d9YvNhpJ3LKkyQOwNGec3' target="_blank"><FaSpotify className='icon mx-3 text-lg hover:text-red-500' /></Routing>
            <Routing to='https://twitter.com/WashingtonToe3' target="_blank"><FaTwitter className='icon mx-3 text-lg' /></Routing>
          </div>
        </BrowserRouter>
      </div>
    </div>
  )
};


export const LaptopDesign = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }
  return (
    <div className='' id='home' name='home'>
      <div className='relative' id='home' name='home'>
        <img src='/banner/banner.png' alt='loogo' />
        <div className='absolute top-8 left-0 w-full '>
          <header>
            <div className="px-10 py-2 w-full flex justify-between text-white bg-transparent">
              <div className="logo">
                <img src="/logo.png" alt="logo" />
              </div>
              <BrowserRouter>
                <div className="icons flex mt-3">
                  <Routing to='https://www.facebook.com/weston.recs/' target="_blank"><FaFacebook className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='https://www.instagram.com/washington_toe/' target="_blank"><FaInstagram className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='https://www.youtube.com/@washingtontoe6261' target="_blank"><FaYoutube className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='#' target="_blank"><FaAmazon className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='https://music.apple.com/us/artist/washington-toe/1671595139' target="_blank"><FaApple className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='https://open.spotify.com/artist/3d9YvNhpJ3LKkyQOwNGec3' target="_blank"><FaSpotify className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                  <Routing to='https://twitter.com/WashingtonToe3' target="_blank"><FaTwitter className='icon mx-3 text-lg hover:text-red-500' /></Routing>
                </div>
              </BrowserRouter>
              <div className="cursor-pointer md:hidden">
                <input class="menu-btn hidden" type="checkbox" id="menu-btn" />
                <label class="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none" for="menu-btn">
                  <span onClick={handleToggle} class="navicon bg-white-darkest flex items-center relative"></span>
                </label>
              </div>
            </div>
          </header>
        </div>
        <div className="top-10 flex left-[33%] z-10 fixed" id="menu">
          <ul className=' flex'>
            <li><a href="/"><Link activeClass="active" to="home" spy={true} smooth={true} duration={700} className="link md:inline-block cursor-pointer hover:text-red-500 border-b md:border-none py-2 px-3">HOME</Link></a></li>
            <li><a href="/"><Link activeClass="active" to="about" spy={true} smooth={true} duration={700} className="link md:inline-block cursor-pointer hover:text-red-500 border-b md:border-none py-2 px-3">ABOUT</Link></a></li>
            <li><a href="/"><Link activeClass="active" to="books" spy={true} smooth={true} duration={700} className="link md:inline-block cursor-pointer hover:text-red-500 border-b md:border-none py-2 px-3">BOOKS</Link></a></li>
            <li><a href="/"><Link activeClass="active" to="music" spy={true} smooth={true} duration={700} className="link md:inline-block cursor-pointer hover:text-red-500 border-b md:border-none py-2 px-3">MUSIC</Link></a></li>
            <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="link md:inline-block cursor-pointer hover:text-red-500 border-b md:border-none py-2 px-3">CONTACT</Link></a></li>
          </ul>
        </div>
        <div className="right_content absolute left-[45%] bottom-16 hover:text-red-500 text-red-300">
          <p>Scroll Down</p>
          <a href="/" className='scroll-bar'><Link activeClass="active" to="about" spy={true} smooth={true} duration={700}><BsBoxArrowDown className=' ml-10' /></Link></a>
        </div>
      </div>
    </div>
  )
}



