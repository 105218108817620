import React from 'react'

const Card = () => {
  return (
    <>
      <div className="container my-12 mx-auto px-4 md:px-12" name='music'>
        <div className=''><h1 className=' justify-start flex md:font-semibold font-medium text-3xl md:text-5xl py-5 md:pl-16 pl-4'>Latest Songs</h1></div>
        <div className="flex flex-wrap justify-around -mx-1 lg:-mx-4 ">
          <div className="my-1 px-5 py-5 w-full md:w-1/2 lg:my-4 lg:px-5 lg:py-5 rounded-2xl lg:w-1/3 bg-[#1f1f1f] hover:scale-105 hover:transition-all hover:duration-1000 hover:ease-linear">
            <article className="overflow-hidden rounded-lg shadow-lg ">
              <a href="https://www.youtube.com/watch?v=JsC1Ty0R0ec">
                <img alt="Placeholder" className="block h-auto w-full" src="/cards/card1.jpeg" target='blank' without rel="noreferrer" />
              </a>
            </article>
          </div>
          <div className="my-1 px-5 py-5 w-full md:w-1/2 lg:my-4 lg:px-5 lg:py-5 rounded-2xl lg:w-1/3 bg-[#1f1f1f] hover:scale-105 hover:transition-all hover:duration-1000 hover:ease-linear">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a href="https://open.spotify.com/artist/3d9YvNhpJ3LKkyQOwNGec3">
                <img alt="Placeholder" className="block h-auto w-full" src="/cards/card2.jpeg" target='blank' without rel="noreferrer" />
              </a>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card