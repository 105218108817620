import React from 'react';
import './WashingtonToe.scss';


const WashingtonToe = () => {
  return (
    <section class="text-gray-600 body-font" name='about'>
      <div class="container mx-auto flex flex-col lg:flex-row items-center lg:items-start md:px-20 py-24">
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:pt-0 lg:items-start">
          <img class="object-cover object-center rounded" alt="hero" src="/washongton.jpg" />
        </div>
        <div class=" text-white lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <p class='font-[poppins] font-medium md:font-semibold text-3xl md:text-5xl mb-6'>Washington Toe</p>
          <p class="leading-8 font-normal text-[14px] font-sans px-5 md:px-0">Washington Samuel Toe is an Author, Producer, Song writer, Screenwriter, Script Contributor, Entrepreneur, and Philanthropist. He currently resides in Minnesota, U.S.A. Washington opened his eyes in Monrovia, the tumultuous capital of Liberia where he spent his formative years. Washington’s life is influenced by the political and civil unrest that has haunted Liberia since its inception. He has witnessed the sinister side of humanity in his home country Liberia and neighboring Sierra Leone where civil wars brought death and devastation to the lives of thousands. He left Liberia in the hope of a better future.
	Washington S. Toe spends most of his leisure time writing songs and fiction stories. He holds deep affection for all art forms, and it is evident in his works as a screenwriter and songwriter. He had been writing fictional stories for a while but the turning point in his writing career arrived in 2005 when he was inspired by Tyler Perry’s Dairy of the Mad Black Woman. He began writing his first manuscript in the U.S. in 2006, named The United Nations’ Volunteers follow by other subsequent projects such as: Over the Line- (Invisible hands over the people’s house), Predicament, Reverse, etc.
	He is a visionary with unwoven passion and deep faith. Washington S. Toe’s life is an inspiration. He valiantly overcame the horrors of his formative years and emerged as a man possessing multiple talents, and an illustrious career. He also duly fulfills all the roles in his personal life, as a responsible and loving father, a great partner, and an obedient son to his late father, Samuel Toe, who passed away in 2014.
	His life is full of struggles and achievements, joys, and sorrows, and this is primarily the reason why he possesses such sagacity and flair in his work that is quite scarce, even among expert writers. 
          </p>
        </div>
      </div>
    </section>
  )
}

export default WashingtonToe;