import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import NavbarFull from "./components/Nav";
// import Hero from "./components/Hero/Hero";
// import Nav from "./components/Nav";
import GalleryAndSlider from "./components/Sec3/Sec3";
import Card from "./components/card/Card";
import Carousal from "./components/carousal/Carousal";
import Gallery from "./components/gallery/Gallery";
import WashingtonToe from "./components/washington-toe/Washington-toe";



function App() {
  return (
    <>
      {/* <Hero /> */}
      <NavbarFull />
      <div className=" md:px-[95px]">
        <WashingtonToe />
        <Carousal />
        <Card />
      </div>
      <GalleryAndSlider />
      {/* <Gallery /> */}
      <Contact />
      <Footer />
    </>
  );
}

export default App;


